<template>
    <router-link
        :to="{
            name: 'successDetail',
            query: {
                id: info.id
            }
        }"
        class="patent"
    >
        <el-image :src="getImg(info.img)" fit="cover"></el-image>

        <div class="content">
            <div class="title">{{ info.name }}</div>
            <div class="info">
                <div class="info-item">
                    <span class="text1">成交时间：</span>
                    <span class="text2">{{ info.soldAt }}</span>
                </div>
                <div class="info-item">
                    <span class="text1">转化来源：</span>
                    <span class="text2">{{ info.soldAddress }}</span>
                </div>
                <!-- <div class="info-item"> -->
                <!-- <span class="text1">成交地点：</span> -->
                <!-- <span class="text2" v-html="info.manual"></span> -->
                <!-- </div> -->
            </div>
            <div class="content_con" v-html="info.manual"></div>
        </div>

        <div class="btn">
            <div class="icon-button" @click.prevent="connectKefu">
                <i class="iconfont iconfont-nav_icon_xiaoxi1"></i>
                咨询
            </div>
            <div @click.prevent="postMessage" class="icon-button">
                <i class="iconfont iconfont-nav_icon_xiaoxi"></i>
                留言
            </div>
        </div>

        <div class="look">
            <i class="iconfont iconfont-icon-lliulan"></i>
            <span>{{ info.view }}个人看过</span>
        </div>
    </router-link>
</template>

<script>
import comEvent from '../../mixins/comEvent';
import { patentTypeOptions } from '../../utils/variables';
export default {
    props: {
        info: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    data() {
        return { patentTypeOptions };
    },
    mixins: [comEvent],
    created() {
        console.log(this.info);
    }
};
</script>

<style lang="less" scoped>
.patent {
    padding: 20px;
    display: flex;
    .el-image {
        width: 182px;
        height: 182px;
        flex-shrink: 0;
    }
    position: relative;
    cursor: pointer;
    border: 1px solid rgba(56, 85, 142, 0.04);
    background: #ffffff;
    &:hover {
        border: 1px solid #01a041;
        .icon-button {
            background: #01a041;
            color: #ffffff;
        }
    }
}

.content {
    margin-left: 20px;
    padding-top: 5px;
    flex-grow: 1;
    padding-right: 50px;
    .title {
        font-size: 16px;
        font-weight: bold;
        color: #292c33;
        line-height: 22px;
    }

    .info {
        margin-top: 15px;
        .flex();
        flex-wrap: wrap;
        .info-item {
            width: 50%;
            padding-right: 50px;
            box-sizing: border-box;
            line-height: 32px;
            .flex();

            .text1 {
                font-size: 13px;
                color: #939599;
                display: inline-block;
                min-width: 65px;
                flex-shrink: 0;
            }

            .text2 {
                font-size: 13px;
                color: #000000;
                margin-left: 10px;
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
        .text2 {
            color: #ccc;
        }
    }
    .content_con {
        margin-top: 10;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #939599;
        line-height: 20px;
    }
}

.look {
    i {
        font-size: 18px;
        color: #c8c9cc;
        vertical-align: middle;
    }
    span {
        font-size: 13px;
        font-weight: 400;
        color: #818999;
        line-height: 18px;
        vertical-align: middle;
        margin-left: 5px;
    }
    position: absolute;
    left: 220px;
    bottom: 25px;
}
.icon-button {
    width: 134px;
    line-height: 36px;
    display: flex;
    justify-content: center;
    border-radius: 2px;
    border: 1px solid #01a041;
    color: #01a041;
    box-sizing: border-box;
    padding: 0px;
}
.icon-button:nth-of-type(1){
    margin-bottom: 16px;
}
.btn {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.edit-btn {
    .flex();
    .el-button {
        &:first-child {
            width: 88px;
        }

        &:last-child {
            width: 56px;
        }
    }
}
</style>
